.imageZoom:hover {
  transform: scale(1.05);
  transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.containerDiv {
  /* width: 60vh;  */
  height: 60vh;
  max-width: 90vw;
  position: relative;
}

.containerDiv .containerImg {
  /* width: 60vh; */
  height: 60vh;
  max-width: 90vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
