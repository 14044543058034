.blurImage {
  filter: blur(1px);
}

.blurImage:hover {
  transform: scale(1.05);
  transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.imageText {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  text-align: center;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  color: black;
  padding: 10px 20px;
  font-weight: bold;
  
}

.blurImage:hover + .imageText {
  background-color: white;
}
