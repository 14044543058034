.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup.show {
  display: block;
}

.popup-content {
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 10px;
}

.popup-content a {
  color: blue;
}

.popup-content button {
  margin-top: 20px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Media query for responsiveness */
@media (max-width: 600px) {
  .popup {
    width: 80%;
  }
}
