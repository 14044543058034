.collection-page {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 600px) {
      padding: 50px;
      grid-template-columns: 1fr;
    }

    & .collection-item {
      margin-bottom: 30px;
    }
  }
}
